<template>

  <router-link :to="slug" ref="project" :title="project.attributes.name" class="card">
    <div class="card-header">
      <img v-if="thumb" :src="thumb" alt="" height="506" width="534">
    </div>
    <div class="card-body">
      <h3 ref="projectTitle">{{ project.attributes.name }}</h3>
      <p ref="projectCopy">{{ project.attributes.description }}</p>
    </div>
    <ul class="project-tech">
      <li v-for="tech in project.attributes.tech" :key="tech" :data-name="tech">{{ tech }}</li>
    </ul>
  </router-link>

</template>


<script>
export default {
  name: 'ProjectCard',
  props: {
    title: {
      type: String,
      default: ""
    },
    project: {
      type: Object
    },
  },
  computed: {
    slug() {
      return this.project.slug;
    },
    thumb() {
      return this.project.images.thumb ? require(`@/assets/${this.project.images.thumb}`) : null;
    },
  },
}
</script>

<style scoped>
a {
  color: var(--black);
  text-decoration: none;
}
.card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card-header {
  height: 20rem;
  background-color: var(--grey);
  border-radius: 0.5rem;
}

@media screen and (min-width: 768px) {
  .card-header {
    height: 33.375rem;
  }
}


img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  pointer-events: none;
  user-select: none;
  border-radius: 0.5rem;
}

.card-body {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

h3 {
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.035rem;
}

p {
  max-width: 25.125rem;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

ul.project-tech {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  max-width: 28rem;
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.project-tech li {
  display: flex;
  flex-wrap: nowrap;
  gap: .5rem;
  font-size: .875rem;
  font-style: italic;
  font-weight: 275;
  line-height: normal;
}


[data-name="User Research"]::before {
  content: url("data:image/svg+xml,%3Csvg width='22' height='17' viewBox='0 0 22 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 0.5C11.0625 0.5 14 2.96875 14 6C14 9.0625 11.0625 11.5 7.5 11.5C6.90625 11.5 6.34375 11.4375 5.78125 11.3125C4.84375 11.9062 3.46875 12.5 1.75 12.5C1.4375 12.5 1.15625 12.3438 1.0625 12.0312C0.9375 11.75 0.96875 11.4375 1.1875 11.1875C1.21875 11.1875 1.90625 10.4375 2.40625 9.4375C1.53125 8.5 1 7.3125 1 6C1 2.96875 3.90625 0.5 7.5 0.5ZM6.125 9.84375C6.59375 9.96875 7.03125 10 7.5 10C10.25 10 12.5 8.21875 12.5 6C12.5 3.8125 10.25 2 7.5 2C4.71875 2 2.5 3.8125 2.5 6C2.5 7.125 3.03125 7.9375 3.5 8.40625L4.25 9.1875L3.75 10.1562C3.625 10.3438 3.5 10.5625 3.375 10.7812C3.9375 10.625 4.46875 10.375 5 10.0312L5.53125 9.71875L6.125 9.84375ZM14.7812 4.53125C18.25 4.65625 21 7.0625 21 10C21 11.3125 20.4375 12.5 19.5625 13.4375C20.0625 14.4375 20.75 15.1875 20.7813 15.1875C21 15.4375 21.0313 15.75 20.9063 16.0312C20.8125 16.3437 20.5313 16.5 20.2188 16.5C18.5 16.5 17.125 15.9062 16.1875 15.3125C15.625 15.4375 15.0625 15.5 14.5 15.5C11.9375 15.5 9.71875 14.25 8.65625 12.4375C9.1875 12.375 9.71875 12.25 10.1875 12.0625C11.0625 13.25 12.6563 14 14.5 14C14.9375 14 15.375 13.9687 15.8438 13.8437L16.4375 13.7187L16.9688 14.0312C17.5 14.375 18.0313 14.625 18.5938 14.7812C18.4688 14.5625 18.3438 14.3437 18.2188 14.1562L17.7188 13.1875L18.4688 12.4062C18.9375 11.9375 19.5 11.125 19.5 10C19.5 7.9375 17.5 6.25 14.9688 6.03125L15 6C15 5.5 14.9062 5 14.7812 4.53125Z' fill='%23341504'/%3E%3C/svg%3E");
}

[data-name="User Journey Workshop"]::before {
  content: url("data:image/svg+xml,%3Csvg width='18' height='15' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.6562 0.65625C17.875 0.78125 18 1.03125 18 1.25L18 11.75C18 12.0625 17.7813 12.3437 17.5 12.4687L12.25 14.4687C12.0938 14.5312 11.9063 14.5312 11.75 14.4687L6 12.5625L1 14.4688C0.78125 14.5625 0.5 14.5312 0.3125 14.375C0.09375 14.25 0 14 0 13.75L0 3.25C0 2.9375 0.1875 2.6875 0.46875 2.5625L5.71875 0.5625C5.875 0.5 6.0625 0.5 6.21875 0.5625L11.9688 2.46875L16.9688 0.5625C17.1875 0.46875 17.4688 0.5 17.6562 0.65625ZM1.5 12.6875L5.25 11.25L5.25 2.34375L1.5 3.78125L1.5 12.6875ZM11.25 3.8125L6.75 2.3125L6.75 11.2187L11.25 12.7187L11.25 3.8125ZM12.75 12.6875L16.5 11.25L16.5 2.34375L12.75 3.78125L12.75 12.6875Z' fill='%23341504'/%3E%3C/svg%3E");
}

[data-name="High-fid Design"]::before, [data-name="Low-fid Design"]::before, [data-name="Report Creation"]::before {
  content: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6562 1.28125C12.5312 0.40625 13.9375 0.40625 14.8125 1.28125L15.2188 1.6875C16.0938 2.5625 16.0938 3.96875 15.2188 4.84375L9.03125 11.0312C8.78125 11.2812 8.4375 11.5 8.0625 11.5937L4.9375 12.5C4.6875 12.5625 4.40625 12.5 4.21875 12.2812C4 12.0938 3.9375 11.8125 4 11.5625L4.90625 8.4375C5 8.0625 5.21875 7.71875 5.46875 7.46875L11.6562 1.28125ZM13.75 2.34375C13.4688 2.0625 13 2.0625 12.7188 2.34375L11.7812 3.25L13.25 4.71875L14.1562 3.78125C14.4375 3.5 14.4375 3.03125 14.1562 2.75L13.75 2.34375ZM6.34375 8.84375L5.8125 10.6875L7.65625 10.1562C7.78125 10.125 7.875 10.0625 7.96875 9.96875L12.1875 5.75L10.75 4.3125L6.53125 8.53125C6.4375 8.625 6.375 8.71875 6.34375 8.84375ZM6.25 2.5C6.65625 2.5 7 2.84375 7 3.25C7 3.6875 6.65625 4 6.25 4L2.75 4C2.03125 4 1.5 4.5625 1.5 5.25L1.5 13.75C1.5 14.4688 2.03125 15 2.75 15L11.25 15C11.9375 15 12.5 14.4687 12.5 13.75V10.25C12.5 9.84375 12.8125 9.5 13.25 9.5C13.6563 9.5 14 9.84375 14 10.25V13.75C14 15.2812 12.75 16.5 11.25 16.5H2.75C1.21875 16.5 0 15.2812 0 13.75L0 5.25C0 3.75 1.21875 2.5 2.75 2.5L6.25 2.5Z' fill='%23341504'/%3E%3C/svg%3E");
}

[data-name="Analytics Research"]::before {
  content: url("data:image/svg+xml,%3Csvg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.75 0.5C1.15625 0.5 1.5 0.84375 1.5 1.25L1.5 12.25C1.5 12.6875 1.8125 13 2.25 13L15.25 13C15.6563 13 16 13.3437 16 13.75C16 14.1875 15.6563 14.5 15.25 14.5L2.25 14.5C1 14.5 0 13.5 0 12.25L0 1.25C0 0.84375 0.3125 0.5 0.75 0.5ZM4 3.75C4 3.34375 4.3125 3 4.75 3L11.25 3C11.6562 3 12 3.34375 12 3.75C12 4.1875 11.6562 4.5 11.25 4.5L4.75 4.5C4.3125 4.5 4 4.1875 4 3.75ZM9.25 6C9.65625 6 10 6.34375 10 6.75C10 7.1875 9.65625 7.5 9.25 7.5L4.75 7.5C4.3125 7.5 4 7.1875 4 6.75C4 6.34375 4.3125 6 4.75 6L9.25 6ZM13.25 9C13.6563 9 14 9.34375 14 9.75C14 10.1875 13.6563 10.5 13.25 10.5L4.75 10.5C4.3125 10.5 4 10.1875 4 9.75C4 9.34375 4.3125 9 4.75 9L13.25 9Z' fill='%23341504'/%3E%3C/svg%3E");
}

[data-name="Video Walkthrough"]::before {
  content: url("data:image/svg+xml,%3Csvg width='12' height='17' viewBox='0 0 12 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.4062 3.4375C11.7812 3.8125 12 4.3125 12 4.84375L12 14.5C12 15.625 11.0938 16.5 10 16.5H2C0.875 16.5 0 15.625 0 14.5L0 2.5C0 1.40625 0.875 0.5 2 0.5L7.65625 0.5C8.1875 0.5 8.6875 0.71875 9.0625 1.09375L11.4062 3.4375ZM10.5 14.5H10.4688L10.4688 5.5L8 5.5C7.4375 5.5 7 5.0625 7 4.5L7 2.03125L2 2.03125C1.71875 2.03125 1.5 2.25 1.5 2.53125L1.5 14.5C1.5 14.7812 1.71875 15 2 15L10 15C10.25 15 10.5 14.7812 10.5 14.5ZM7.5 9.5V10.0312L8.84375 9.09375C9.125 8.90625 9.5 9.09375 9.5 9.40625V12.625C9.5 12.9375 9.125 13.125 8.84375 12.9375L7.5 12V12.5C7.5 13.0625 7.03125 13.5 6.5 13.5L3.5 13.5C2.9375 13.5 2.5 13.0625 2.5 12.5L2.5 9.5C2.5 8.96875 2.9375 8.5 3.5 8.5H6.5C7.03125 8.5 7.5 8.96875 7.5 9.5Z' fill='%23341504'/%3E%3C/svg%3E");
}

</style>
