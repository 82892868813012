<template>
  <homepage-hero
      :content="homepageContent">
  </homepage-hero>
  <projects-block></projects-block>
</template>

<script>
// @ is an alias to /src
import ProjectsBlock from "@/views/ProjectsBlock.vue";
import HomepageHero from "@/components/HomepageHero.vue";

export default {
  name: 'HomeView',
  components: {ProjectsBlock, HomepageHero},
  data() {
    return {
      homepageContent: "<del>Passionate</del> <del>Enthusiastic</del> <del>Impassioned</del> <strong><em>Multi-faceted</em></strong> digital product and web designer with a blend of artistic creativity and problem-solving.",
    };
  },
  mounted() {
    document.title = "Chloe Burch | Product Designer";
  }
}
</script>
