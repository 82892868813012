<template>
  <div class="projects-block">
    <div class="container">
      <div class="projects-grid">
        <ProjectCard
            v-for="project in projects"
            :key="project.id"
            :project="project"
            :title="project.title"
        />
      </div>
    </div>
  </div>
</template>

<script>
import jsonData from '../projects.json';
import ProjectCard from "@/components/ProjectCard";

export default {
  name: "ProjectsBlock",
  components: {ProjectCard},
    data() {
      return {
        projects: jsonData.projects
      };
    },
  };
</script>

<style scoped>
.projects-block {
  margin: 6.5rem 0 6rem;
}

.projects-grid {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3.5rem 1.5rem;
  padding: 0 2rem;
  border-left: 1px solid var(--black);
}

@media screen and (min-width: 768px) {
  .projects-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .projects-grid {
    padding: 0.5rem 6.625rem;
  }
}

.projects-grid::before {
  content: "";
  position: absolute;
  top: 20%;
  left: 0;
  width: 0.5625rem;
  height: 50%;
  background-color: var(--black);
}
</style>
