<template>
  <div class="hero" tabindex="0">
    <img :src="require(`@/assets/blob-1.png`)" alt="" id="hero-blob">
    <img :src="require(`@/assets/blob-2.png`)" alt="" id="hero-blob-2">

    <div class="container">
      <div class="hero-content">
        <div class="hero-text">
          <img :src="require(`@/assets/product-designer.png`)" alt="Product Designer">
          <p v-html="content"></p>
        </div>
        <div class="hero-image">
          <img :src="require(`@/assets/badges.png`)"
               alt="A collection of badges with the text, user experience, visual storytelling, design systems and user research"
               height="620" width="620">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomepageHero',
  props: {
    content: {
      type: String,
      default: ""
    },
  },
}
</script>

<style scoped>
.hero {
  position: relative;
  display: flex;
  height: 100%;
  background-color: var(--black);
  overflow: hidden;
}

@media screen and (min-height: 796px) {
  .hero {
    max-height: 98.8125rem;
  }
}

.hero-content {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  margin: 8rem 0 5rem;
  color: var(--white);
  border-left: 1px solid var(--grey);
}


.hero-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0.5625rem;
  height: 8.6875rem;
  background-color: var(--grey);
}

@media screen and (min-width: 768px) {
  .hero-content {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 93px;
  }
}

@media screen and (min-width: 1024px) {
  .hero-content {
    margin: 9rem 0;
  }
}

p {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  max-width: 25rem;
}

.hero-text {
  padding: 4.25rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2.12rem;
}

@media screen and (min-width: 1024px) {
  .hero-text {
    padding: 4.25rem 0 4.25rem 6.625rem;
  }
}

.hero-text img {
  max-width: 31.75rem;
  width: 100%;
}

.hero-image {
  position: static;
  max-width: 577px;
}

.hero-image img {
  position: relative;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
}

#hero-blob {
  position: absolute;
  top: 0;
}

#hero-blob-2 {
  position: absolute;
  top: 35%;
  right: 0;
  width: 100%;
  opacity: 0;
}

@media screen and (min-width: 768px) {
  #hero-blob-2 {
    width: auto;
    opacity: 1;
  }
}

img {
  user-select: none;
  pointer-events: none;
}
</style>
